@import "@fontsource/work-sans";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Work Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*debut gestion de la scrollbar*/
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border: 0 none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-thumb:active {
  /*background: pink;*/
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border: 0 none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #e8e3e3;
}

::-webkit-scrollbar-track:active {
  /*background: #333333;*/
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* Exception pour masquer la scrollbar de cet élément car il ne doit pas être scrollable */
div#bubblesContainer::-webkit-scrollbar {
  display: none;
}
/*fin gestion de la scrollbar*/